<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','详情'):$l('project.add','详情')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-steps :active="status" finish-status="success" simple style="margin-top: 20px">
      <el-step title="报修"></el-step>
      <el-step title="认领"></el-step>
      <el-step title="到达"></el-step>
      <el-step title="作业完成"></el-step>
      <el-step title="客户确认完成"></el-step>
      <el-step title="审批完成"></el-step>
    </el-steps>
    <el-tabs value="first" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <el-tag class="tag">设备信息</el-tag>
        <div class="vm-separate content">
          <div>楼盘名称:{{elevator.location.realEstateName}}</div>
          <div>详细地址:{{elevator.location.detailAddress}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>楼宇名称:{{elevator.location.buildingName}}</div>
          <div>有无机房:{{elevator.location.machineRoom?'有':"无"}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>注册代码:{{elevator.brief.regCode}}</div>
          <div>出厂编号:{{elevator.brief.factoryCode}}</div>
          <div>出厂日期:{{elevator.brief.factoryDate}}</div>
        </div>
        <div class="vm-separate content">
          <div>电梯品牌:{{elevator.brief.brandName}}</div>
          <div>电梯型号:{{elevator.brief.modelName}}</div>
          <div>控制系统:{{elevator.brief.controlSystemDesc}}</div>
        </div>
        <!--        <div class="vm-separate content">-->
        <!--          <div>项目名称:{{elevator.maintain.maintenanceProjectName}}</div>-->
        <!--          <div>开始时间:{{elevator.maintain.startTime}}</div>-->
        <!--          <div>结束时间:{{elevator.maintain.endTime}}</div>-->
        <!--        </div>-->
        <!--        <div class="vm-separate content">-->
        <!--          <div>使用单位:{{elevator.maintain.usingCompanyName}}</div>-->
        <!--          <div>客户姓名:{{elevator.maintain.usingCompanyContactName}}</div>-->
        <!--          <div>客户电话:{{elevator.maintain.usingCompanyContactPhone}}</div>-->
        <!--        </div>-->
        <!--        <div class="vm-separate content">-->
        <!--          <div>维保班组:{{elevator.maintain.teamName}}</div>-->
        <!--          <div>队长姓名:{{elevator.maintain.teamLeaderName}}</div>-->
        <!--          <div>队长电话:{{elevator.maintain.teamLeaderPhone}}</div>-->
        <!--        </div>-->
        <el-tag class="tag">工单信息</el-tag>
        <div class="vm-separate content">
          <div>工单编号:{{model.workOrder.orderCode}}</div>
          <div>工单类型:{{model.workOrder.typeDesc}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>认领人姓名:{{model.workOrder.assigneeName}}</div>
          <div>认领人电话:{{model.workOrder.assigneePhone}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>认领时间:{{model.workOrder.assignedTime}}</div>
          <div>是否认领超时:{{model.workOrder.assignedTimeoutLevel?"是":"否"}}</div>
          <div>认领超时等级:{{model.workOrder.assignedTimeoutLevel}}</div>
        </div>
        <div class="vm-separate content">
          <div>到达时间:{{model.workOrder.arrivedTime}}</div>
          <div></div>
          <div></div>
        </div>
        <el-table
          :data="model.taskVos"
          label-position="left"
          class="demo-table-expand">
          <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

          <el-table-column prop="assigneeName" label="协同人姓名" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="协同人电话" align="center">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="急修信息" name="second">
        <el-tag class="tag">报修信息</el-tag>
        <div class="vm-separate content">
          <div>报修人:{{model.report.reporterName}}</div>
          <div>报修人电话:{{model.report.reporterPhone}}</div>

          <div>报修时间:{{model.report.reportedTime}}</div>
        </div>

        <div class="vm-separate content">
          <div>故障现象:{{model.report.faultPhenomenon}}</div>
          <div>是否重复报修:{{model.report.isRepeat?'是':'否'}}</div>
          <div>故障类别:{{model.report.faultCategory}}</div>
        </div>
        <div class="vm-separate content">
          <div>是否滑梯坠梯:{{model.report.elevatorFalling?"是":"否"}}</div>
          <div>是否停梯:{{model.report.elevatorStopped?"是":"否"}}</div>
          <div>是否困人:{{model.report.peopleTrapped?"是":"否"}}</div>
        </div>
        <div class="vm-separate content">
          <div>备注:{{model.report.reportRemark}}</div>
        </div>
        <el-tag class="tag">维修信息</el-tag>
        <div class="vm-separate content">
          <div>现象:{{model.report.faultPhenomenon}}</div>
        </div>
        <div class="vm-separate content">
          <div>原因:{{model.workOrder.faultReason}}</div>
        </div>
        <div class="vm-separate content">
          <div>处理结果:{{model.workOrder.repairResult}}</div>
        </div>
        <div class="vm-separate content">
          <div>完成时间:{{model.workOrder.completedTime}}</div>
          <div>是否完成超时:{{model.workOrder.completedTimeout?"是":"否"}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>维保人员签字: <span v-if="model.logVoList"><img v-for="(item,index) in model.logVoList" :key="index" style="width: 200px" :src="ip+item.signature"/></span><span v-else></span></div>
          <div></div>
          <div></div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="客户反馈" name="third">
        <el-tag class="tag">客户信息</el-tag>
        <div class="vm-separate content">
          <div>客户姓名:{{model.workOrder.customerName}}</div>
          <div>联系电话:{{model.workOrder.customerPhone}}</div>
          <div></div>
        </div>
        <div class="vm-separate content">
          <div>工作态度满意度:{{model.workOrder.attitudeScore}}</div>
        </div>
        <div class="vm-separate content">
          <div>工作质量满意度:{{model.workOrder.qualityScore}}</div>
        </div>
        <div class="vm-separate content">
          <div>意见:{{model.workOrder.customerComment}}</div>
        </div>
        <div class="vm-separate content">
          <div>客户签字:<img v-if="model.workOrder.documentPhoto" :src="ip+model.workOrder.documentPhoto"/><span v-else></span></div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="故障信息" name="four">
        <vm-table
          ref="faultTable"
          :filter="{orderId:repairOrderId, orderColumnName:'id',asc:0}"
          url="elevator/iot/fault-statistics/page">
          <template slot="adSearch">
            <div class="vm-search">
              <vm-search :label="$l('project.name','故障代码')">
                <el-input v-model.trim="filter.faultCode" clearable></el-input>
              </vm-search>
              <vm-search label="是否解除">
                <el-select v-model="filter.removed">
                  <el-option :value="true" label="是"></el-option>
                  <el-option :value="false" label="否"></el-option>
                </el-select>
              </vm-search>
            </div>
          </template>

          <el-table-column
            align="center"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column prop="faultCode" :label="$l('project.name','故障代码')"></el-table-column>
          <el-table-column prop="faultName" label="故障名称"></el-table-column>
          <el-table-column prop="position" label="故障最近发生楼层">
          </el-table-column>
          <el-table-column prop="lastOccurredTime" label="故障最近发生时间">
          </el-table-column>
          <el-table-column prop="cause" label="可能原因">
          </el-table-column>
          <el-table-column prop="description" label="维修建议" align="center"></el-table-column>
          <el-table-column prop="solution" label="解决方案" align="center"></el-table-column>
          <el-table-column prop="orderProposal" label="工单处理对策" align="center"></el-table-column>
          <el-table-column prop="removed" label="是否解除">
            <template slot-scope="scope">
              {{scope.row.removed?"是":"否"}}
            </template>
          </el-table-column>

          <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
            <template slot-scope="scope">
              <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
              <!--          <el-dropdown trigger="click">-->
              <!--            <el-button type="primary">-->
              <!--              更多操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--            </el-button>-->
              <!--            <el-dropdown-menu slot="dropdown">-->
              <!--              <el-dropdown-item icon="el-icon-circle-plus" command="verify" @click.native="$refs.verificationPage.open(scope.row.id,scope.row.reportRemark)">情况调查核实</el-dropdown-item>-->
              <!--              <el-dropdown-item icon="el-icon-circle-plus" command="cancel" @click.native="$refs.cancelPage.open(scope.row.id,scope.row.reportRemark)">情况核实取消</el-dropdown-item>-->
              <!--              <el-dropdown-item icon="el-icon-circle-plus" command="cancel" @click.native="toTask(scope.row)">转任务</el-dropdown-item>-->
              <!--              <el-dropdown-item type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "取消")}}</el-dropdown-item>-->
              <!--            </el-dropdown-menu>-->
              <!--          </el-dropdown>-->
              <el-button type="success" @click="$refs.repairDispatch.open(scope.row.id)">{{$l("common.delete", "派单")}}</el-button>
              <el-button type="danger" @click="$refs.cancelPage.open(scope.row.id)">{{$l("common.delete", "取消")}}</el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane label="维修历史" name="fiv">
        <vm-table
          ref="repairTable"
          :filter="{elevatorId:elevatorId,completed:1}"
          height="200"
          url="maintenance/repair-orders">
          <el-table-column prop="teamName" label="维保班组" align="center"></el-table-column>
          <el-table-column prop="assigneeName" label="维修人员" align="center"></el-table-column>
          <el-table-column prop="orderCode" label="工单编号" align="center"></el-table-column>
          <el-table-column prop="typeDesc" label="维修类型" align="center"></el-table-column>
          <el-table-column prop="completedTime" label="维修完成时间" align="center"></el-table-column>
          <el-table-column prop="faultPhenomenon" label="故障现象" align="center"></el-table-column>
          <el-table-column prop="faultReason" label="故障原因" align="center"></el-table-column>
          <el-table-column prop="repairResult" label="处理结果" align="center"></el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane label="审批信息" name="six">
        <div class="vm-separate content">
          <div>审批人:{{model.logs.operatorName}}</div>
          <div>联系电话:{{model.logs.operatorPhone}}</div>
          <div>审批时间:{{model.logs.operatedTime}}</div>
        </div>
        <div class="vm-separate content">
          <div>备注:{{model.logs.text}}</div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="打印记录" name="seven">
        <vm-table
          ref="printTable"
          :filter="{businessId:repairOrderId,businessType:'repair',operateType:'order_print'}"
          url="authentication/business-operation-log-page">
          <el-table-column
            align="center"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column prop="operatedTime" :label="$l('project.name','时间')"></el-table-column>
          <el-table-column prop="operatorName" label="姓名"></el-table-column>
          <el-table-column prop="operatorPhone" label="电话">
          </el-table-column>
          <el-table-column prop="ip" label="ip">
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane label="工单日志" name="eight">
        <vm-table
          ref="logTable"
          :filter="{businessId:repairOrderId,businessType:'repair'}"
          url="authentication/business-operation-log-page">
          <el-table-column
            align="center"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column prop="operatedTime" :label="$l('project.name','时间')"></el-table-column>
          <el-table-column prop="operatorName" label="姓名">
            <template slot-scope="scope">
              {{scope.row.operatorName?scope.row.operatorName:'系统'}}
            </template>
          </el-table-column>
          <!--          <el-table-column prop="operatorPhone" label="电话">-->
          <!--          </el-table-column>-->
          <el-table-column prop="operateTypeDesc" label="操作类型">
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane label="地图" name="nine">
        <vm-circle-map v-if="dialogVisible" :coordinate="coordinate" :sign-radius="signRadius"></vm-circle-map>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
  import commonUtil from "@/util/commonUtil";
  import VmCircleMap from "@/components/VmCircleMap";
  export default {
    components: {VmCircleMap},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        status: 0,
        repairOrderId: "",
        submitLoading: false,
        filter:{elevatorId:this.id},
        elevator:{location:{},brief:{},maintain:{}},
        signRadius:500,
        coordinate:{
          assign:{
            lng:122.163035,
            lat:37.42041,
            name:"张三",
          },
          arrive:{
            lng:122.173035,
            lat:37.42041,
            name:"张三"},
        },
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:{},
          elevator:{},
          logs:{},
          workOrder:{},
          report:{},
          activities:[],
          faults:[],
          maintainTask:{},
          logVoList:[],
        },
        ip:window.config.pictureUrl,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        console.log(302,pageNum);
        const time= window.setTimeout(()=>{
          this.$refs.faultTable.getList(pageNum);
          this.$refs.repairTable.getList(pageNum);
          this.$refs.printTable.getList(pageNum);
          this.$refs.logTable.getList(pageNum);
          window.clearTimeout(time);
        },100);
      },
      getElevator() {
        this.$http.get(`elevator/summary/${this.elevatorId}`).then(res=>{
          this.elevator=res;
        });
      },
      // getRepairOrder() {
      //   this.$http.get(`maintenance/repair-order/${this.id}`).then(res=>{
      //     console.log(res,285);
      //     this.order=res;
      //   });
      // },
      open(row) {
        this.model.id = row.repairOrderId?row.repairOrderId:row.id;
        this.elevatorId = row.elevatorId;
        this.repairOrderId = row.repairOrderId?row.repairOrderId:row.id;
        this.filter.elevatorId=row.elevatorId;
        this.id = row.elevatorId;
        this.status= commonUtil.orderStatus(row.orderStatus);
        console.log(this.status,302);
        this.dialogVisible = true;
        this.getData();
        this.getElevator();
        this.getList(1);
        // this.getRepairOrder();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/repair-order/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            console.log(data,340);
            if(!data.logs) {
              this.model.logs={};
            }
            if(!data.workOrder) {
              this.model.workOrder={};
            }
            if(!data.report) {
              this.model.report={};
            }
            if (data.realEstate) {
              this.signRadius = data.realEstate.signRadius;
              console.log(3777777777,data.realEstate.assigneeLongitude-data.realEstate.arrivedLongitude);
              if (Math.abs(data.realEstate.assigneeLongitude-data.realEstate.arrivedLongitude)<0.00002 &&Math.abs(data.realEstate.assigneeLongitude-data.realEstate.arrivedLongitude)<0.00002) {
                this.coordinate = {
                  assign:{
                    lng:data.realEstate.assigneeLongitude+0.00002<data.realEstate.latitude+500?data.realEstate.assigneeLongitude+0.00002:data.realEstate.assigneeLongitude-0.00002,
                    lat:data.realEstate.assigneeLatitude+0.00002<data.realEstate.longitude+500?data.realEstate.assigneeLatitude+0.00002:data.realEstate.assigneeLatitude-0.00002,
                    name:data.realEstate.assigneeName,
                    time:data.realEstate.assignedTime,
                  },
                  arrive:{
                    lng:data.realEstate.arrivedLongitude,
                    lat:data.realEstate.arrivedLatitude,
                    name:data.realEstate.arrivedName,
                    time:data.realEstate.arrivedTime,
                  },
                  latitude:data.realEstate.latitude,
                  longitude:data.realEstate.longitude,
                  name:data.realEstate.name,
                };
              }else {
                this.coordinate = {
                  assign:{
                    lng:data.realEstate.assigneeLongitude,
                    lat:data.realEstate.assigneeLatitude,
                    name:data.realEstate.assigneeName,
                    time:data.realEstate.assignedTime,
                  },
                  arrive:{
                    lng:data.realEstate.arrivedLongitude,
                    lat:data.realEstate.arrivedLatitude,
                    name:data.realEstate.arrivedName,
                    time:data.realEstate.arrivedTime,
                  },
                  latitude:data.realEstate.latitude,
                  longitude:data.realEstate.longitude,
                  name:data.realEstate.name,
                };
              }
            }

            console.log(this.coordinate,103);
            // const arr =[{content:data}];

          });
        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/maintain-plan", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tag{
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
  color: black;
  font-weight: bolder;
  border: none;
  background-color: white;
}
.content{
  margin: 5px 0 5px 15px;
}
</style>
