<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','报修派单'):$l('project.add','报修派单')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <div class="vm-separate">
        <el-form-item :label="$l('project.name','维保班组')" prop="teamName">
          <vm-autocomplete
            :model.sync="model"
            :filter.sync="filter.teamId"
            :value-name="model.teamName"
            value-key="name"
            linkage-name="id"
            require-name="teamId"
            :value="{teamName:'name',teamId:'id'}"
            url="organization/team/list"></vm-autocomplete>
        </el-form-item>
        <el-form-item :label="$l('project.name','班组成员')" prop="memberName">
          <vm-autocomplete
            :model.sync="model"
            :value-name="model.memberName"
            value-key="name"
            :value="{memberName:'name',primaryAssigneeId:'id'}"
            require-name="teamId"

            :linkage="{filter:filter}"
            url="organization/employee/list"></vm-autocomplete>
        </el-form-item>
        <el-form-item :label="$l('project.name','次要责任人')" prop="secondaryAssigneeIds">
          <el-select v-model="model.secondaryAssigneeIds" :multiple="true" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb($event)"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer($event)"></customer-select>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/customer/CustomerSelect";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";
  import VmAutocomplete from "@/components/VmAutocomplete";
  // import moment from "moment";
  export default {
    components: {VmAutocomplete, UserSelect, CustomerSelect,ProjectSelect,ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        id:"",
        filter:{},
        options:[],
        model: {
          id: 0,
          name: "",
          teamName:"",
          teamId:"",
          status: "",
          tasks:[],
          tasksMapping:[],
        },
      };
    },
    watch:{
      model:{
        handler(parmas,i) {
          if (parmas.teamId!==i.teamId) {
            this.$http.get("organization/employee/list", {teamId:parmas.teamId}).then(res => {
              this.options = res;
            });
          }
        },
        deep:true,
      },
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/repair-report/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            if (data.teamId) {
              this.filter.teamId=data.teamId;

            }else {
              this.filter.teamId="";
            }
            this.$http.get("organization/employee/list", {teamId:this.filter.teamId}).then(res => {
              this.options = res;
            });
          });

        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },

      elevatorSelect(row) {
        console.log(row,187);
        this.$set(this.model,"elevatorId",row.id);
        this.$set(this.model,"elevatorName",row.name);

      },
      projectSelect(row) {

        this.$set(this.model,"projectId",row.id);
        this.$set(this.model,"projectName",row.name);

      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .post(`maintenance/repair-report/${this.id}/assigned`, parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("派单成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.tasks.push({
          key: this.$util.genKey(),
          "id": 0,
          "tenantId": 0,
          "planId": 0,
          "projectId": 0,
          "elevatorId": 0,
          "cycle": "",
          "planDate": "",
          "planWorkHour": 0,
          "assigned": true,
          "assigneeId": 0,
          "assignedTime": "",
          "maintainOrderId": 0,
          "actualStartTime": "",
          "actualEndTime": "",
          "actualWorkHour": 0,
          "projectName": "",
          "elevatorCode": "",
          "assigneeName": "",
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>