<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="报修情况调查核实"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      label-width="auto">
      <el-form-item label="核实人" prop="assigneeId">
        <vm-select-input
          :value="model.assigneeName"
          placeholder="选择实际核实人,不填则默认核实人为当前操作用户"
          @select="$refs.userSelect.open();"></vm-select-input>
      </el-form-item>

      <el-form-item :label="$l('project.projectName','电梯停梯')" prop="name">
        <el-select v-model="model.elevatorStopped">
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$l('project.peopleTrapped','人员被困')" prop="name">
        <el-select v-model="model.peopleTrapped">
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
     
      <el-form-item label="计划日期" prop="planDate">
        <el-date-picker
          v-model="model.planDate"
          type="datetime"
          placeholder="选择核实日期,不填则默认为提交时间"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="计划工时" prop="planWorkHour" :rules="$rule.notNull">
        <el-input v-model="model.planWorkHour" :rows="2" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="核实备注" prop="verificationRemark" :rules="$rule.notNull">
        <el-input v-model="model.verificationRemark" type="textarea" :rows="2" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelect" @select="selectUserWb"></user-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";

  export default {
    components: {UserSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        reportRemark:null,
        model: {
          verifierName: null,
          verifiedTime: null,
          verificationRemark:null,
        },
      };
    },
    methods: {
      open(id,reportRemark) {
        this.dialogVisible = true;
        this.model.id = id;
        this.reportRemark = reportRemark;
      },
      selectUserWb(row) {

        this.$set(this.model,"assigneeId",row.id);
        this.$set(this.model,"assigneeName",row.name);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("maintenance/repair-report/"+this.model.id+"/verified", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("报修情况调查核实提交成功");
              }).catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
